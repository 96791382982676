<template>
  <div class="contentWholeWrap">
    <!-- BEGIN header -->
    <v-layout
      row
      wrap
      class="mb-4"
    >
      <h2 class="headline font-weight-medium">
        Update User
      </h2>
      <!-- <v-spacer class="hidden-xs-only"/>
      <div class="createInfo caption text-md-right mr-3">
        <p>
          <label class="labelStyle">Creator : </label>
          {{ createInfo.name }}
        </p>
        <p>
          <label class="labelStyle">Create Date : </label>
          {{ createInfo.date }}
        </p>
      </div> -->
    </v-layout>
    <!-- END header -->

    <!-- BEGIN content -->
    <div class="contentBoxWrap">
      <div class="contentBox">
        <v-layout>
          <v-flex
            md6
            offset-md3
          >
            <x-formgroup
              v-model="scheme.valid"
              class="w100"
            >
              <!-- BEGIN input group -->
              <div class="pa-2">
                <!--                 <div>
                  {{ $t('Role.role') }}:
                  {{ $t(`Role.types[${this.$store.user.role}]`) }}
                </div> -->
                <x-textfield
                  v-model="scheme.userType.value"
                  v-bind="scheme.userType"
                />
                <x-textfield
                  v-model="scheme.userName.value"
                  v-bind="scheme.userName"
                />
                <!-- <div>{{ $t('User.email') }}: {{ this.$store.user.email }}</div> -->
                <x-textfield
                  v-model="scheme.email.value"
                  v-bind="scheme.email"
                />
                <x-textfield
                  v-model="scheme.password.value"
                  v-bind="scheme.password"
                />
                <x-textfield
                  v-model="scheme.passwordConfirm.value"
                  v-bind="scheme.passwordConfirm"
                />
              </div>
              <!-- END input group -->
              <v-layout class="actionBtnGroup">
                <x-button
                  v-bind="scheme.buttonCancel"
                  class="btnInfo"
                />
                <x-button
                  v-bind="scheme.buttonSave"
                  class="btnSecondary"
                />
              </v-layout>
            </x-formgroup>
          </v-flex>
        </v-layout>
      </div>
    </div>
    <!-- END content -->
  </div>
</template>

<script>
export default {
  data () {
    return {
      scheme: {
        userName: {
          label: this.$t('User.name'),
          type: 'text',
          placeholder: 'enter user name',
          value: this.$store.user.name
        },

        userType: {
          label: this.$t('User.role'),
          placeholder: 'select user type',
          value: this.$t(`Role.types[${this.$store.user.role}]`),
          disabled: true
        },

        email: {
          label: this.$t('User.email'),
          appendIcon: 'email',
          placeholder: 'enter user email',
          value: this.$store.user.email,
          disabled: true
        },

        password: {
          label: 'Set Password',
          type: 'password',
          placeholder: 'password',
          appendIcon: 'lock',
          value: '',
          rules: []
        },

        passwordConfirm: {
          label: 'Confirm Password',
          type: 'password',
          placeholder: 'password',
          appendIcon: 'lock',
          value: '',
          rules: []
        },

        buttonCancel: {
          content: 'Cancel',
          size: 'large',
          outline: true,
          click: () => {
            this.$router.go(-1)
          }
        },

        buttonSave: {
          content: 'Save',
          size: 'large',
          click: this.submit
        }
      }
    }
  },
  methods: {
    async submit () {
      this.$store.loading = true
      try {
        const password = this.scheme.password.value
        if (password !== this.scheme.passwordConfirm.value) {
          throw this.$t('User.validation.password_not_match')
        }
        await this.$Role.updateUser({
          userId: this.$store.user.id,
          name: this.scheme.userName.value,
          password
        })
        this.$store.successNotification = this.$t('User.form.update_success')
        this.$router.replace({ name: 'RoleList' })
      } catch (e) {
        this.$store.errorNotification = e
      }
      this.$store.loading = false
    }
  }
}
</script>

<style lang="stylus" scoped>
.createInfo{
  p{
    margin: 0;
  }
}
</style>
